<template>
  <introduction-template
    :service-name="$route.name"
    :title="$t(`button['video content analysis']`)"
    :description="$t(`services.vca.description`)"
    :particle-options="particleOptions"
    :features="servicesCards"
    service-abbrev="VCA"
  >
    <template slot="banner">
      <banner class="animate-svg-blocks" />
    </template>
  </introduction-template>
</template>
<script>
// import iconACT from '@/assets/images/icons/services/introduction/vca/act.svg';
import iconCLS from '@/assets/images/icons/services/introduction/vca/cls.svg';
import iconOBJ from '@/assets/images/icons/services/introduction/vca/obj.svg';
import iconQUA from '@/assets/images/icons/services/introduction/vca/qua.svg';
import iconSEG from '@/assets/images/icons/services/introduction/vca/seg.svg';
import iconPSE from '@/assets/images/icons/services/introduction/vca/pse.svg';
import iconTAG from '@/assets/images/icons/services/introduction/vca/tag.svg';
import iconLAD from '@/assets/images/icons/services/introduction/vca/lad.svg';
import iconCBT from '@/assets/images/icons/services/introduction/vca/cbt.svg';
import iconNSFW from '@/assets/images/icons/services/introduction/vca/nsfw.svg';
import Banner from '../../components/BannerBlocks/VCA.vue';
import IntroductionTemplate from '../../components/IntroductionTemplate/Index.vue';

export default {
  components: { IntroductionTemplate, Banner },
  data() {
    return {
      particleOptions: { color: '#D9CAFF', linesColor: '#521FD1' },
      servicesCards: [
        {
          icon: iconCLS,
          title: this.$t('button[\'video classification\']'),
          introduction: this.$t('services.vca.CLS'),
          link: 'video-classification',
        },
        // Removed this temporary, service is not ready
        // {
        //   icon: iconACT,
        //   title: this.$t('button[\'action recognition\']'),
        //   introduction: this.$t('services.vca.ACT'),
        //   link: 'action-recognition',
        // },
        {
          icon: iconQUA,
          title: this.$t('button[\'video quality estimation\']'),
          introduction: this.$t('services.vca.QUA'),
          link: 'video-quality-estimation',
        },
        {
          icon: iconTAG,
          title: this.$t('button[\'video tagging\']'),
          introduction: this.$t('services.vca.TAG'),
          link: 'video-tagging',
        },
        {
          icon: iconOBJ,
          title: this.$t('button[\'video object detection\']'),
          introduction: this.$t('services.vca.OBJ'),
          link: 'video-object-detection',
        },
        {
          icon: iconSEG,
          title: this.$t('button[\'video instance segmentation\']'),
          introduction: this.$t('services.vca.SEG'),
          link: 'video-segmentation',
        },
        {
          icon: iconPSE,
          title: this.$t('button[\'video pose estimation\']'),
          introduction: this.$t('services.vca.PSE'),
          link: 'video-pose-estimation',
        },
        {
          icon: iconLAD,
          title: this.$t('button[\'video landmark detection\']'),
          introduction: this.$t('services.vca.LAD'),
          link: 'video-landmark-detection',
        },
        {
          icon: iconCBT,
          title: this.$t('button[\'video celebrity detection\']'),
          introduction: this.$t('services.vca.CBT'),
          link: 'video-celebrity-detection',
        },
        {
          icon: iconNSFW,
          title: this.$t('button[\'video not safe for work\']'),
          introduction: this.$t('services.vca.NSFW'),
          link: 'video-not-safe-for-work',
        },
      ],
    };
  },
};
</script>
